import { graphql } from "gatsby";
import Reviews from "../../templates/reviews";
import links from "/src/scripts/aboutLinks";

const ReviewsPage = ({
	data: {
		craft: { entry },
	},
}) => <Reviews entry={entry} subLinks={links} />;

export const pageQuery = graphql`
	query ReviewsPage {
		craft {
			entry(slug: "reviews") {
				... on CraftCMS_reviews_reviews_Entry {
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;
export default ReviewsPage;
