const links = [
	{
		url: "/about-us/why-lending-people",
		text: "Why Lending People",
		type: "entry",
	},
	{
		url: "/about-us/our-board",
		text: "Our Board",
		type: "entry",
	},
	{
		url: "/about-us/careers",
		text: "Careers",
		type: "entry",
	},
	{
		url: "/about-us/reviews",
		text: "Reviews",
		type: "entry",
	},
];

export default links;
